import {Injectable} from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
  state: string;
  name?: string;
  type?: string;
  icon?: string;
  children?: ChildrenItems[];
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

const HEADERMENUITEMS = [
  {
    state: 'home',
    name: 'home.menu.home',
    type: 'link'
  },
  {
    state: 'menu',
    name: 'home.menu.menu',
    type: 'sub',
    icon: '',
    children: [
      {
        state: '/assets/teahouse/menu.pdf',
        name: 'home.menu.main',
        type: 'file'
      },
      {
        state: '/assets/teahouse/dimsum.pdf',
        name: 'home.menu.dimsum',
        type: 'file'
      },
      {
        state: '/assets/teahouse/drinks.pdf',
        name: 'home.menu.drinks',
        type: 'file'
      }
    ]
  },
  {
    state: 'contact',
    name: 'home.menu.contact',
    type: 'link'
  },
  {
    state: 'takeaway',
    name: 'home.menu.takeaway',
    type: 'link'
  },
  {
    state: 'reservation',
    name: 'home.menu.reservation',
    type: 'link'
  }
];

const FooterOneItems = [
  {
    state: '',
    name: 'Company',
    type: 'sub',
    icon: '',
    children: [
      {
        state: 'home',
        name: 'Home',
        type: 'link'
      }
    ]
  }
];

@Injectable()
export class MenuItems {

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return HEADERMENUITEMS;
  }

  /*
   * Get all footer menu
   */
  getFooterOneMenu(): Menu[] {
    return FooterOneItems;
  }
}
